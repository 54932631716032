

import { useEffect, useRef, useState } from 'react';
import axios from "axios";
import './vouchers.css';
import { useSearchParams } from 'react-router-dom';
import { ahbapi } from '../config/axios/axiosconfig';
import DOMPurify from 'dompurify';



export default function Vouchers() {

    const [searchParams] = useSearchParams();
    const [responseMessage, setResponseMessage] = useState("");
    const [offer, setOffer] = useState();
    const [voucher, setVoucher] = useState();

    const [isSuccess, setIsSuccess] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const offerId = searchParams.get("offerId")

    // console.log(offerId)
    //  https://stg-catalyst-external-distributor-api.pulseid.com/affiliate-redirect-url?offId=679&affId=13&pubId=37&externalUserId=AHB0001
    const getOffer = async () => {
        try {
            const res = await ahbapi.get("/ahb-api/ahb/getoffer", {
                params: {
                    offerId: offerId
                }
            });
            console.log(res.data)
            setOffer(res.data);

            const euid = searchParams.get("UID")
            const res2 = await ahbapi.post("/ahb-api/ahb/getvouchercode", {
                offerId: offerId,
                externalUserId: euid ? euid : ""
            });
            console.log(res2.data)
            setVoucher(res2.data.split(" ")[1]);

        } catch (err) {
            console.log(err);
            setResponseMessage("Something went wrong. Please try again later.")
        }
    }

    useEffect(() => {
        getOffer()
    }, []);




    return <div className="container mt-3 m-auto" style={{ maxWidth: "360px" }}>
        <div className="row text-center">
            <div className="col align-content-center">
                <img src={offer?.merchant.image} style={{ width: "75%" }} alt="" />
            </div>
            <div className="col align-content-center">
                <img src="images/logos/ahb_logo.png" style={{ width: "75%" }} alt="" />
            </div>
        </div>
        <div className="row mt-4 mb-5">

            <div className="row">
                <div className="col mb-3">
                    <h5 style={{ fontSize: "1.05rem" }}>{offer?.title}</h5>
                </div>
                <div className="rowa">
                    <div className="d-inline-block" style={{ width: "40%", verticalAlign:"middle" }}>
                        <div id="divOD" className="carousel slide" data-bs-ride="carousel">
                            {/* Carousel indicators */}
                            <div className="carousel-indicators od-carousel-indicators">
                                {offer?.images.map((image, index) => (
                                    <button
                                        type="button"
                                        data-bs-target="#divOD"
                                        data-bs-slide-to={index}
                                        className={`${index === 0 ? "active" : ""} bg-dark`}
                                        key={index}
                                    ></button>
                                ))}
                            </div>

                            {/* Carousel inner */}
                            <div className="carousel-inner">
                                {offer?.images.map((image, index) => (
                                    <div
                                        className={`carousel-item od-carousel-item ${index === 0 ? "active" : ""}`}
                                        key={index}>
                                        <img src={DOMPurify.sanitize(image)} className="d-block w-100 rounded-4" alt="" />
                                    </div>
                                ))}
                            </div>

                            {/* Carousel controls */}
                            {offer?.images.length > 1 ? (
                                <button className="carousel-control-prev carousel-control-od"
                                    type="button"
                                    data-bs-target="#divOD"
                                    data-bs-slide="prev">
                                    <i className=" bg-light bi bi-chevron-left border border-1 rounded-circle text-dark"
                                        style={{ width: "25px", height: "25px" }}></i>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                            ) : (
                                ""
                            )}
                            {offer?.images.length > 1 ? (
                                <button className="carousel-control-next carousel-control-od"
                                    type="button"
                                    data-bs-target="#divOD"
                                    data-bs-slide="next">
                                    <i className="bg-light bi bi-chevron-right border border-1 rounded-circle text-dark"
                                        style={{ width: "25px", height: "25px" }} ></i>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div className="d-inline-block text-center" style={{ width: "60%", verticalAlign: "middle" }}>
                        <h3 className='voucher-code'>{voucher}</h3>
                    </div>
                </div>
            </div>

            <div>
                <div className="mt-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(offer?.description) }}></div>
            </div>
            <div>
                <div className="mt-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(offer?.termsAndConditions) }}></div>
            </div>
        </div>


    </div>

}