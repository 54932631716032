
import { Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "bootstrap-icons/font/bootstrap-icons.css";

import './App.css';

import Home from './pages/Home';
import ShowNSave from './pages/ShowNSave';
import Vouchers from './pages/Vouchers';

function App() {



  return (


    <>


      <Routes>

        <Route path={"/shownsave/*"} element={<ShowNSave />} />
        <Route path={"/vouchers/*"} element={<Vouchers />} />

        <Route path={"/*"} element={<Home />} />
      </Routes>

    </>

  );

}

export default App;
