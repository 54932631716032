

import { useEffect, useRef, useState } from 'react';
import axios from "axios";
import './shownsave.css';
import { useSearchParams } from 'react-router-dom';
import { ahbapi } from '../config/axios/axiosconfig';
import DOMPurify from 'dompurify';



export default function ShowNSave() {

    const txtCode = useRef();
    const [searchParams] = useSearchParams();
    const [responseMessage, setResponseMessage] = useState("");
    const [offer, setOffer] = useState();
    const [isSuccess, setIsSuccess] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const offerId = searchParams.get("offerId")
    // console.log(offerId)
    //  https://stg-catalyst-external-distributor-api.pulseid.com/affiliate-redirect-url?offId=679&affId=13&pubId=37&externalUserId=AHB0001
    const getOffer = async () => {
        try {
            const res = await ahbapi.get("/ahb-api/ahb/getoffer", {
                params: {
                    offerId: offerId
                }
            });
            //console.log(res.data)
            setOffer(res.data);

        } catch (err) {
            console.log(err);

            setResponseMessage("Something went wrong. Please try again later.")
        }
    }

    useEffect(() => {
        getOffer()
    }, []);

    const submitPinCode = async () => {
        setResponseMessage("")
        setShowLoader(true)
        setIsSuccess(false)

        const pinCode = txtCode.current.value;
        //console.log("pinCode", pinCode)
        if (pinCode === "") {
            setResponseMessage("PIN Code Required.")
            setShowLoader(false)

        } else {
            try {
                const euid = searchParams.get("UID")
                const res = await ahbapi.post("/ahb-api/ahb/updatepincode",
                    {
                        offerId: offerId,
                        pinCode: pinCode,
                        externalUserId: euid ? euid : ""
                    }
                );
                setResponseMessage("You have successfully redeemed the offer. Enjoy!")
                setIsSuccess(true);
                setShowLoader(false)

            } catch (err) {
                console.log(err)
                setResponseMessage("Invalid PIN, please check and enter again.")
                setShowLoader(false)

            }
        }
    }


    return <div className="container mt-3 m-auto" style={{ maxWidth: "360px" }}>
        <div className="row text-center">
            <div className="col align-content-center">
                <img src={offer?.merchant.image} style={{ width: "75%" }} alt="" />
            </div>
            <div className="col align-content-center">
                <img src="images/logos/ahb_logo.png" style={{ width: "75%" }} alt="" />
            </div>
        </div>
        <div className="row mt-4 mb-5">
            <div className="col mb-3">
                <h5 style={{ fontSize: "1.05rem" }}>{offer?.title}</h5>
            </div>
            <div className="rowa">
                <div className="d-inline-block" style={{ width: "100%" }}>
                    <div id="divOD" className="carousel slide me-4 ms-4" data-bs-ride="carousel">
                        {/* Carousel indicators */}
                        <div className="carousel-indicators od-carousel-indicators">
                            {offer?.images.map((image, index) => (
                                <button
                                    type="button"
                                    data-bs-target="#divOD"
                                    data-bs-slide-to={index}
                                    className={`${index === 0 ? "active" : ""} bg-dark`}
                                    key={index}
                                ></button>
                            ))}
                        </div>

                        {/* Carousel inner */}
                        <div className="carousel-inner">
                            {offer?.images.map((image, index) => (
                                <div
                                    className={`carousel-item od-carousel-item ${index === 0 ? "active" : ""}`}
                                    key={index}>
                                    <img src={DOMPurify.sanitize(image)} className="d-block w-100 rounded-4" alt="" />
                                </div>
                            ))}
                        </div>

                        {/* Carousel controls */}
                        {offer?.images.length > 1 ? (
                            <button className="carousel-control-prev carousel-control-od"
                                type="button"
                                data-bs-target="#divOD"
                                data-bs-slide="prev">
                                <i className=" bg-light bi bi-chevron-left border border-1 rounded-circle text-dark"
                                    style={{ width: "25px", height: "25px" }}></i>
                                <span className="visually-hidden">Previous</span>
                            </button>
                        ) : (
                            ""
                        )}
                        {offer?.images.length > 1 ? (
                            <button className="carousel-control-next carousel-control-od"
                                type="button"
                                data-bs-target="#divOD"
                                data-bs-slide="next">
                                <i className="bg-light bi bi-chevron-right border border-1 rounded-circle text-dark"
                                    style={{ width: "25px", height: "25px" }} ></i>
                                <span className="visually-hidden">Next</span>
                            </button>
                        ) : (
                            ""
                        )}
                    </div>

                    {/* <img src={`${(offer?.images.length > 0) ? offer?.images[0] : ""}`} style={{ width: "100%" }} alt="" /> */}
                </div>
                {/* <div className="d-inline-block ps-3" style={{ width: "60%", verticalAlign: "top" }}>
                    <div>
                        Terms: <label dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(offer?.termsAndConditions) }}></label>
                    </div>
                </div> */}
            </div>
            <div class="mb-3 mt-3 text-center">
                <input type="password" ref={txtCode} required class="form-control text-center mb-3 rounded-4" id="txtCode" placeholder="Enter the PIN Code" />

                {
                    (showLoader) ? <div>
                        <div class="spinner-border" role="status">
                            {/* <span class="visually-hidden"></span> */}
                        </div>
                    </div>
                        :
                        (isSuccess) ?
                            <div className='text-success fs-1'>
                                <i class="bi bi-check-circle-fill"></i>
                            </div>
                            :
                            <button type="button" class="btn btn-primary rounded-5"
                                onClick={submitPinCode}>Continue</button>
                }



                <div><small className={`${(isSuccess) ? "text-success" : "text-danger"}`}>{responseMessage}</small></div>
            </div>
            <div>
                <div className="mt-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(offer?.description) }}></div>

            </div>
            <div className='text-center pb-5 mt-4'>
                <i class="bi bi-exclamation-circle"></i>
                <small>Ask the shop assistant to enter the pin code</small>
                <br />
                <small>* Valid when paying with your Al Hilal Card</small>
            </div>
            <div className='text-center pt-5 text-secondary'>
                Use this eGift card in-store
            </div>
        </div>



        {/* <div className="small">
            <p>Terms: Offer valid when paying with any Al Hilal Bank card, for a single use per customer, until 8th April 2024. Present your unique barcode, either digital or printed, at checkout for the cashier to scan.</p>
            <p className="text-end">الشروط: يسري العرض عند الدفع باستخدام أي من <br />
                بطاقات مصرف الهلال، وذلك لمرة واحدة لكل متعامل، <br />
                حتى تاريخ 8 أبريل 2024. يرجى تقديم الرمز الخاص <br />
                (الباركود) الخاص بك عند الدفع.</p>
        </div> */}
    </div>

}