
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Barcode from "react-barcode";

import "./home.css";

export default function Home() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [b1g1, setB1g1] = useState("AHB");
    const [b6g6, setB6g6] = useState("AHB");

    useEffect(() => {
        setB1g1(searchParams.get("b1g1"));
        setB6g6(searchParams.get("b6g6"));
    }, []);

    return <div className="container mt-5 m-auto" style={{ maxWidth: "360px" }}>
        <div className="row text-center">
            <div className="col">
                <img src="images/logos/dunkin_logo.svg" style={{ width: "75%" }} alt="" />
            </div>
            <div className="col">
                <img src="images/logos/ahb_logo.png" style={{ width: "75%" }} alt="" />
            </div>
        </div>
        {
            (b1g1) ? <div className="row mt-5 mb-5">
                <div className="col mb-3">
                    <h5 style={{ fontSize: "1.05rem" }}>Buy 1 Get 1 Free Coffee</h5>
                </div>
                <div className="rowa">
                    <div className="d-inline-block" style={{ width: "40%" }}>
                        <img src="images/offers/b1g1.jpg" style={{ width: "100%" }} alt="" />
                    </div>
                    <div className="d-inline-block" style={{ width: "60%", verticalAlign: "middle" }}>
                        <div><Barcode value={b1g1} width={1} height={50} fontSize={12} /></div>
                    </div>
                </div>
            </div>
                : ""
        }
        {
            (b6g6) ? <div className="row mt-5 mb-5">
                <div className="col mb-3">
                    <h5 style={{ fontSize: "1.05rem" }}>Buy 6 get 6 free donuts for 42 AED</h5>
                </div>
                <div className="rowa">
                    <div className="d-inline-block" style={{ width: "40%" }}>
                        <img src="images/offers/b6g6.jpg" style={{ width: "100%" }} alt="" />
                    </div>
                    <div className="d-inline-block" style={{ width: "60%", verticalAlign: "middle" }}>
                        <div><Barcode value={b6g6} width={1} height={50} fontSize={12} /></div>
                    </div>
                </div>
            </div>
                : ""
        }

        <div className="small">
            <p>Terms: Offer valid when paying with any Al Hilal Bank card, for a single use per customer, until 8th April 2024. Present your unique barcode, either digital or printed, at checkout for the cashier to scan.</p>
            <p className="text-end">الشروط: يسري العرض عند الدفع باستخدام أي من <br />
                بطاقات مصرف الهلال، وذلك لمرة واحدة لكل متعامل، <br />
                حتى تاريخ 8 أبريل 2024. يرجى تقديم الرمز الخاص <br />
                (الباركود) الخاص بك عند الدفع.</p>
        </div>
    </div>

}